import React, {
  Fragment,
  useState,
  useRef,
  useEffect,
  useLayoutEffect,
  createRef,
  PropsWithChildren,
  ReactNode,
} from 'react';
import { StyledComponent } from 'styled-components';

function usePrevious(value: ReactNode) {
  const ref = useRef<ReactNode>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const calculateBoundingBoxes = (children: ReactNode) => {
  const boundingBoxes = {};

  React.Children.forEach(children, (child) => {
    // @ts-ignore
    const domNode = child.ref.current;
    if (domNode) {
      const nodeBoundingBox = domNode.getBoundingClientRect();

      // @ts-ignore
      boundingBoxes[child.key] = nodeBoundingBox;
    }
  });

  return boundingBoxes;
};

const AnimatedContent = ({ children }: PropsWithChildren) => {
  const [boundingBox, setBoundingBox] = useState({});
  const [prevBoundingBox, setPrevBoundingBox] = useState({});
  const prevChildren = usePrevious(children);

  useEffect(() => {
    const newBoundingBox = calculateBoundingBoxes(children);
    setBoundingBox(newBoundingBox);
  }, [children]);

  useEffect(() => {
    if (prevChildren) {
      const prevBoundingBoxCalc = calculateBoundingBoxes(prevChildren);
      setPrevBoundingBox(prevBoundingBoxCalc);
    }
  }, [prevChildren]);

  useLayoutEffect(() => {
    const hasPrevBoundingBox = Object.keys(prevBoundingBox).length;

    if (hasPrevBoundingBox) {
      React.Children.forEach(children, (child) => {
        if (child === null || child === undefined) return;
        // @ts-ignore
        const domNode = child.ref.current;
        // @ts-ignore
        const firstBox = prevBoundingBox[child.key];
        // @ts-ignore
        const lastBox = boundingBox[child.key];

        const changeInX = firstBox && firstBox.left - lastBox.left;
        if (changeInX) {
          requestAnimationFrame(() => {
            // Before the DOM paints, invert child to old position
            domNode.style.transform = `translateX(${changeInX}px)`;
            domNode.style.transition = 'transform 0s';

            requestAnimationFrame(() => {
              // After the previous frame, remove
              // the transistion to play the animation
              domNode.style.transform = '';
              domNode.style.transition =
                'all 500ms cubic-bezier(.15,.01,.75,1)';
            });
          });
        }
      });
    }
  }, [boundingBox, prevBoundingBox, children]);

  return children;
};

const AnimatedContentWrapper = ({
  children,
  Wrapper = Fragment,
}: PropsWithChildren<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Wrapper?: StyledComponent<any, never> | typeof Fragment;
}>) => {
  return (
    <div style={{ display: 'flex' }} data-testid={'animated-wrapper'}>
      <Wrapper>
        <AnimatedContent>
          {children &&
            React.Children.map(children, (child, index) => {
              return (
                <div
                  // @ts-ignore
                  key={child?.key || child}
                  id={`${index}`}
                  ref={createRef()}
                >
                  {child}
                </div>
              );
            })}
        </AnimatedContent>
      </Wrapper>
    </div>
  );
};

export default AnimatedContentWrapper;
