import styled from 'styled-components';
import { MAX_DESKTOP, BASE_SPACING } from 'components/styles';

type WrapType = {
  removePadding?: boolean;
  useMaxWidth?: boolean;
};

const Wrap = styled.div<WrapType>`
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0 ${({ removePadding }) => (removePadding ? 0 : BASE_SPACING)};
  ${({ useMaxWidth }) =>
    useMaxWidth
      ? `
    max-width: 100%;
    width: 100%;
  `
      : `
    max-width: ${MAX_DESKTOP};
  `}
`;

export default Wrap;
