import styled from 'styled-components';
import { MIN_DESKTOP, MIN_TABLET } from 'components/styles';

const ProfilePageLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;

  @media (min-width: ${MIN_TABLET}) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: ${MIN_DESKTOP}) {
    grid-template-columns: 1fr 2fr;
  }
`;

export default ProfilePageLayout;
