import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

const recursiveStacking = `* + * {
margin-top: var(--space);
}`;

const nonRecursiveStacking = `> * + * {
margin-top: var(--space);
}`;

const StyledStack = styled.div<{ space: string; recursive: boolean }>`
  --space: ${({ space }) => space};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  > * {
    margin-top: 0;
    margin-bottom: 0;
  }

  ${({ recursive }) => (recursive ? recursiveStacking : nonRecursiveStacking)}
`;

type StackProps = { space?: string; recursive?: boolean };

const Stack = ({
  space = '1rem',
  recursive = false,
  children,
}: PropsWithChildren<StackProps>) => (
  <StyledStack space={space} recursive={recursive}>
    {children}
  </StyledStack>
);

export default Stack;
