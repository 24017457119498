import React, {
  useState,
  useRef,
  useEffect,
  PropsWithChildren,
  RefObject,
  ReactNode,
} from 'react';
import { useInterval } from 'helpers';
import {
  ButtonIcon,
  ControlButton,
  Container,
  CarouselWrapper,
  CarouselHeader,
} from './carousel.styles';

export type CarouselProps = {
  heading?: ReactNode | undefined;
  scrollPage?: number;
  className?: string;
  testId?: string;
  displayControls?: boolean;
  maxCarouselWidth?: string;
  buttonColor?: 'dark' | 'light';
  displayGradient?: boolean;
  keepGradientEnabled?: boolean;
  autoScroll?: boolean;
  autoScrollDelay?: number;
};

type WrapperValues = {
  offsetLeft: number;
  scrollLeft: number;
  scrollWidth: number;
  offsetWidth: number;
};

const initialWrapperValues: WrapperValues = {
  offsetLeft: 0,
  scrollLeft: 0,
  scrollWidth: 0,
  offsetWidth: 0,
};

const Carousel = ({
  children,
  heading,
  scrollPage,
  className,
  testId = 'carousel',
  displayControls = true,
  maxCarouselWidth = '1280px',
  buttonColor = 'dark',
  displayGradient = true,
  keepGradientEnabled = false,
  autoScroll = false,
  autoScrollDelay = 10000,
}: PropsWithChildren<CarouselProps>) => {
  const [displayLeftControl, setDisplayLeftControl] = useState<boolean>(
    displayControls,
  );
  const [displayRightControl, setDisplayRightControl] = useState<boolean>(
    displayControls,
  );
  const [isMouseDown, setMouseDown] = useState<boolean>(false);
  const [startX, setStartX] = useState<number>(0);
  const [mouseX, setMouseX] = useState<number>(0);
  const [mouseY, setMouseY] = useState<number>(0);
  const [scrollLeft, setScrollLeft] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setPage] = useState<number>(0);
  const wrapper = useRef<WrapperValues>(initialWrapperValues);

  const handleMouseDown: React.MouseEventHandler<HTMLDivElement> = (e) => {
    setMouseDown(true);
    setStartX(e.pageX - wrapper.current.offsetLeft);
    setScrollLeft(wrapper.current.scrollLeft);

    setMouseX(e.clientX);
    setMouseY(e.clientY);
  };

  const handleMouseUp: React.MouseEventHandler<HTMLDivElement> = () => {
    setMouseDown(false);
  };

  const handleMouseLeave: React.MouseEventHandler<HTMLDivElement> = () => {
    setMouseDown(false);
  };

  const handleMouseMove: React.MouseEventHandler<HTMLDivElement> = (e) => {
    if (!isMouseDown) return;
    e.preventDefault();
    const x = e.pageX - wrapper.current.offsetLeft;
    const walk = (x - startX) * 1;
    wrapper.current.scrollLeft = scrollLeft - walk;
  };

  const handleClickCapture: React.MouseEventHandler<HTMLDivElement> = (e) => {
    const xDistance = e.clientX - mouseX;
    const yDistance = e.clientY - mouseY;

    const distance = Math.sqrt(xDistance * xDistance + yDistance * yDistance);

    if (distance > 5) {
      e.stopPropagation();
      e.preventDefault();
    }
  };

  useEffect(() => {
    setTotalPages(
      Math.ceil(wrapper?.current?.scrollWidth / wrapper?.current?.offsetWidth),
    );
    wrapper.current.scrollLeft = currentPage * wrapper?.current?.offsetWidth;

    setDisplayLeftControl(displayControls && currentPage !== 0);
    setDisplayRightControl(displayControls && currentPage + 1 < totalPages);
  }, [wrapper, scrollPage, currentPage, displayControls, totalPages, children]);

  useInterval(() => {
    if (autoScroll) {
      const nextPage = currentPage + 1 > totalPages ? 0 : currentPage + 1;
      setPage(nextPage);
    }
  }, autoScrollDelay);

  return (
    <>
      <CarouselHeader>{heading}</CarouselHeader>
      <Container maxCarouselWidth={maxCarouselWidth}>
        {displayLeftControl && (
          <ControlButton
            alignment="left"
            type="button"
            onClick={() => setPage(currentPage - 1)}
            data-testid="carousel-left-button"
          >
            <ButtonIcon color={buttonColor} icon="chevronLeftCarousel" />
          </ControlButton>
        )}
        <CarouselWrapper
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseLeave}
          onClickCapture={handleClickCapture}
          className={className}
          ref={wrapper as RefObject<HTMLDivElement>}
          data-testid={testId}
          displayGradient={displayGradient}
          keepGradientEnabled={keepGradientEnabled}
        >
          {children}
        </CarouselWrapper>
        {displayRightControl && (
          <ControlButton
            alignment="right"
            type="button"
            onClick={() => setPage(currentPage + 1)}
            data-testid="carousel-right-button"
          >
            <ButtonIcon color={buttonColor} icon="chevronRight" />
          </ControlButton>
        )}
      </Container>
    </>
  );
};

export default Carousel;
