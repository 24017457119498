import styled from 'styled-components';
import { MIN_TABLET, HALF_BASE_SPACING, BASE_SPACING } from 'components/styles';

type GridProps = {
  gap?: string;
  width?: string;
};

const Grid = styled.div<GridProps>`
  display: grid;
  grid-gap: ${({ gap = '1rem' }) => gap};

  @supports (width: min(${({ width = '250px' }) => width}, 100%)) {
    grid-template-columns: repeat(
      auto-fit,
      minmax(min(${({ width = '250px' }) => width}, 100%), 1fr)
    );
  }
  padding-bottom: ${HALF_BASE_SPACING};

  @media (min-width: ${MIN_TABLET}) {
    padding-bottom: ${BASE_SPACING};
  }
`;

export default Grid;
