import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { MAX_TABLET } from 'components/styles';

type SwitcherProps = {
  threshold?: string;
  space?: string;
  limit?: number;
  className?: string;
};

const SwitcherWrap = styled.div<{
  threshold: string;
  space: string;
  limit: number;
}>`
  > * {
    display: flex;
    flex-wrap: wrap;
    margin: calc((${({ theme }) => theme.spacing.BASE_SPACING} / 2) * -1);
  }

  > * > * {
    flex-grow: 1;
    flex-basis: calc(
      (${({ threshold }) => threshold} - (100% - ${({ space }) => space})) * 999
    );
    margin: calc(${({ space }) => space} / 2);
  }

  > * > :nth-last-child(n + ${({ limit }) => limit + 1}),
  > * > :nth-last-child(n + ${({ limit }) => limit + 1}) ~ * {
    flex-basis: 100%;
  }
`;

const Switcher = ({
  children,
  space = '16px',
  threshold = MAX_TABLET,
  limit = 5,
  className,
}: PropsWithChildren<SwitcherProps>) => (
  <SwitcherWrap
    className={className}
    space={space}
    threshold={threshold}
    limit={limit}
  >
    <div>{children}</div>
  </SwitcherWrap>
);

export default Switcher;
