import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

const ClusterWrapper = styled.div<{ space: string }>`
  --space: ${({ space }) => space};
  overflow: hidden;

  > * {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    margin: calc(var(--space) / 2 * -1);
  }

  > * > * {
    margin: calc(var(--space) / 2);
  }
`;

type ClusterProps = {
  className?: string;
  space?: string;
};

const Cluster = ({
  className,
  space = '1rem',
  children,
}: PropsWithChildren<ClusterProps>) => {
  return (
    <ClusterWrapper className={className} space={space}>
      <div>{children}</div>
    </ClusterWrapper>
  );
};

export default Cluster;
